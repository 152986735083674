import React from 'react'; 

import { Heading, Gallery } from '../../../components';



const GallerySection = () => {    
  return (
    <section>
      <Heading title={'our gallery'} />
      <Gallery /> 
    </section>
  )
}

export default GallerySection;