import React from 'react';
import './Footer.css';

import { Logo } from '../../components';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaAngleDoubleRight } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai'; 
import { BsMapFill } from 'react-icons/bs';

import { Link } from 'react-router-dom';

  
  
const Footer = () => {  
  
  return (
    <footer className='footer'>

      <div className='box-container'>

        <div className='footer-item'> 
          <Logo /> 
          <p>We teach, model, and encourage a love of learning and combine Quranic teachings with Computer Science and other modern disciplines.</p>
        </div>

        <div className='footer-item'>
          <h2>our services</h2>
          <div className='info links'>
            <p><Link to='/single-service'>Learning Quran</Link></p> 
            <p><Link to='/single-service'>Marriage Service</Link></p> 
            <p><Link to='/single-service'>Counseling</Link></p> 
            <p><Link to='/single-service'>Community service</Link></p> 
            <p><Link to='/single-service'>Mosque Development</Link></p> 
            <p><Link to='/single-service'>Funeral Service</Link></p> 
          </div>
        </div> 

        <div className='footer-item'>
          <h2>useful links</h2>
          <div className='info links pages'>
            <div className='links-item'>
              <p><Link to='/'>home</Link></p> 
              <p><Link to='/about-us'>about</Link></p>
              <p><Link to='/services'>services</Link></p>
              <p><Link to='/causes'>causes</Link></p>
              <p><Link to='/events'>events</Link></p>
            </div>
            <div className='links-item'>
              <p><Link to='/gallery'>gallery</Link></p>
              <p><Link to='/blog-grid'>blogs</Link></p>
              <p><Link to='/shop-grid'>shop</Link></p>
              <p><Link to='/contact-us'>contact</Link></p>
            </div>
          </div>
        </div> 

        <div className='footer-item'>
          <h2>contact info</h2>
          <div className='info'>
            <p><AiTwotonePhone className='icon' /><span>+111-222-333</span></p>
            <p><AiTwotonePhone className='icon' /><span>+123-456-789</span></p>
            <p><FaEnvelope className='icon' /><span className='gmail'>abc@gmail.com</span></p>
            <p><FaEnvelope className='icon' /><span className='gmail'>xyz@gmail.com</span></p>
            <p><BsMapFill className='icon' /><span>karachi, pakistan</span></p>
          </div>
          <div className='social'>
            <a href="/#"><FaFacebookF className='icon' /></a>
            <a href="/#"><FaTwitter className='icon' /></a>
            <a href="/#"><FaInstagram className='icon' /></a>
            <a href="/#"><FaLinkedin className='icon' /></a>
          </div>
        </div>

      </div>

      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;