import React from 'react';
import './Cause.css';

import { Link } from 'react-router-dom'; 
import { AiTwotoneCalendar } from 'react-icons/ai';

     
 
const Cause = (props) => { 
  const {image, title, date, content, raised, goal, cause} = props;
  return (  
    <div className='cause-item'>
 
        <div className='image'> 
          <img src={image} alt='Cause' />
        </div>
 
        <div className='content'>
          <Link to={{
              pathname: '/Causes/' + title,
            }} 
            state= {cause}
            className='main-heading'>{title}
          </Link>
          <div className='date'><AiTwotoneCalendar className='icon' /><span>{date}</span></div>
          <p>{content}</p>   
          <div className='skill-bar'><span className='skill-progress' style={{width: Math.floor((raised * 100) / goal) + `%`}}><span className='skill-no'>{Math.floor((raised * 100) / goal)}%</span></span></div>
          <div className='amount'>
            <p>Raised: <span>${raised}</span></p>
            <p>Goal: <span>${goal}</span></p>
          </div>
        </div>

    </div>
  )
}

export default Cause;