import React from 'react'; 
import './Pillars.css';

import { Heading } from '../../../components';
import { pillars } from '../../../constants';

   
 
const Pillars = () => {    
  return (
    <section className='pillars linear-bg'>

      <Heading title={'pillars of islam'} />

      <div className='box-container'>
      { 
        pillars.map((pillar) => {
          return <div className='pillar-item' key={pillar.id}>
            <div className='icon'>{pillar.icon}</div>
            <h4>{pillar.title}</h4>
            <p>{pillar.content}</p>
          </div>
        })
      }
      </div>

    </section>
  )
}

export default Pillars;