import React from 'react';
import './SingleEvent.css';

import { EventSidebar, PageTitle, TeamItem } from '../../../components';

import { FaCalendarAlt, FaMap, FaPhone, FaEnvelope, FaUserAlt, FaCircle } from 'react-icons/fa';
import { BsPinMapFill } from 'react-icons/bs';
import { BiTime } from 'react-icons/bi';
import { GiTicket } from 'react-icons/gi';
 
import { useLocation } from "react-router-dom";

 

function SingleEvent() {

    const location = useLocation();
    const event = location.state;

    return (
        <>
            <PageTitle title={'single event'} page={'single event'} />
            <section className='single-event'>

                <div className='event-container'>

                    <div className='event-info'>
                        <div className='image'> 
                            <img src={event.image} alt='Event' />
                            <div className='date'>{event.date}</div>
                        </div>
                        <div className='content'>

                            <div className='details'>
                                <h3><BiTime className='icon' /><span>{event.time}</span></h3>
                                <h3><FaMap className='icon' /><span>{event.location}</span></h3>
                            </div>

                            <h3 className='main-heading'>{event.title}</h3>

                            {
                                (event.paragraph).map((item) => {
                                    return <p key={item.id} className='text'>{item.title}</p>
                                })
                            } 

                            <div className='event-content'>
                                <h6>Event Content:</h6>
                                <ul>
                                {
                                (event.eventContent).map((item) => {
                                    return <li key={item.id}><FaCircle className='icon' />{item.title}</li>
                                })
                            }
                            </ul>
                            </div>

                            <div className='event-speakers'>

                                <h6>Our Speakers:</h6>

                                <div className='box-container'>
                                { 
                                    event.speakers.map((speaker) => {  
                                        return(
                                        <TeamItem 
                                            key={speaker.id} 
                                            image={speaker.image} 
                                            name={speaker.name}
                                            title={speaker.title}
                                            accounts={speaker.accounts}
                                            team={speaker} 
                                        />)
                                    })
                                }

                                </div>

                            </div>

                            <div className='event-information'>

                                <h6>Event Details:</h6>

                                <div className='box-container'>
                                    <ul>
                                        <li><FaCalendarAlt className='icon' /><h4>date:</h4><span>{event.date}</span></li>
                                        <li><BiTime className='icon' /><h4>time:</h4><span>{event.time}</span></li>
                                        <li><BsPinMapFill className='icon' /><h4>venue:</h4><span>{event.venue}</span></li>
                                        <li><FaMap className='icon' /><h4>location:</h4><span>{event.location}</span></li>
                                    </ul>
                                    <ul>
                                        <li><FaPhone className='icon' /><h4>phone:</h4><span>{event.phone}</span></li>
                                        <li><FaEnvelope className='icon' /><h4>email:</h4><span className='gmail'>{event.email}</span></li>
                                        <li><FaUserAlt className='icon' /><h4>organizer:</h4><span>{event.organizer}</span></li>
                                        <li><GiTicket className='icon' /><h4>event ticket:</h4><span>${event.cost}</span></li>
                                    </ul> 

                                </div>

                                <a href="#" className='btn'>book now</a>
                                
                            </div>

                        </div>
                    </div>
                </div>

                <EventSidebar />

            </section>
            
            
        </>
    )
}

export default SingleEvent;
 