import React from 'react';
import './Heading.css';

import { FaMosque } from 'react-icons/fa';


const Heading = ({title}) => {
  return (

    <div className='heading'>
      <div className='content'>

        <h2>{title}</h2>
        <div className='design'>
          <span></span>
          <FaMosque className='icon' />
          <span></span>
        </div>
        
      </div>
    </div>

  ) 
} 

export default Heading