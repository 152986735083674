import React from 'react';
import './EventSidebar.css';

import { SidebarHeading } from '../../components';
import { eventCategories, eventPosts, eventTags } from '../../constants';

import { BsSearch } from 'react-icons/bs';
import { AiTwotoneCalendar } from 'react-icons/ai';

import { Link } from 'react-router-dom'; 
 

  
const EventSidebar = () => { 
  return (  
    <div className='sidebar event-sidebar'>
 
        <div className='search sidebar-item'>
            <SidebarHeading title='search' />
            <div className='input-box'>
                <input type="search" id="search" placeholder="search..." />
                <label htmlFor="search"><BsSearch /></label>
            </div>
        </div>


        <div className='category sidebar-item'>
            <SidebarHeading title='categories' />
            <div className='box-container'>
            {
                eventCategories.map((category) => {
                    return(
                        <div className='item' key={category.id}>
                            <a href="#">{category.title}</a>
                            <p>({category.quantity})</p>
                        </div>
                    )
                })
            }
            </div>   
        </div>  


        <div className='recent-post sidebar-item'>
            <SidebarHeading title='recent posts' />
            <div className='box-container'>
            {
                eventPosts.map((event) => { 
                    return( 
                        <div className='event-item' key={event.id}>
                            <img src={event.image} alt='Event' />
                            <div className='content'>
                                <Link to={{
                                    pathname: '/events/' + event.title,
                                    }} 
                                    state= {event}
                                    className='main-heading'>{event.title}
                                </Link>
                                <h3><AiTwotoneCalendar className='icon' /><span>{event.date}</span></h3>
                            </div>
                    
                        </div>
                    )
                })
            } 
            </div>
        </div>


        <div className='tags sidebar-item'>
            <SidebarHeading title='tags' />
            <div className='box-container'>
                {
                    eventTags.map((tag) => {
                        return <div className='tag-item' key={tag.id}>{tag.title}</div>
                    })
                }
            </div>
        </div>
        

    </div>
  )
}

export default EventSidebar;