import { AiTwotonePhone } from 'react-icons/ai'; 
import { FaEnvelope } from 'react-icons/fa';
import { BsMapFill, BsClockFill } from 'react-icons/bs';

  
const contacts = [ 
    { 
        id: 1,
        icon: <AiTwotonePhone />,
        title: 'contact',
        content: [
            {id: 1, value: '+123-456-789,'},
            {id: 2, value: '+111-222-333'}
        ],
    },
    {
        id: 2,
        icon: <BsClockFill />,
        title: 'opening hours',
        content: [
            {id: 1, value: 'Mon - Fri : 8am - 10pm,'},
            {id: 2, value: 'Sat : 10am - 6pm'}
        ],
    },
    {
        id: 3,
        icon: <FaEnvelope />,
        title: 'email',
        content: [
            {id: 1, value: 'abc@gmail.com,'},
            {id: 2, value: 'xyz@gmail.com'}
        ],
    },
    {
        id: 4,
        icon: <BsMapFill />,
        title: 'address',
        content: [
            {id: 1, value: 'karachi, pakistan'}
        ],
    },
 
];

export default contacts;