import React from 'react';
import {Home, About, Counter, Services, Team, Testimonials, Blogs, Pillars, Prayers, Events, Causes, GallerySection} from '../../sections'; 

 
const HomePage = () => { 
  return ( 
      <div className='homepage'>
        <Home />
        <About />
        <Services />
        <Pillars />
        <GallerySection /> 
        <Team />
        <Prayers />
        <Events />
        <Causes />
        <Counter />
        <Testimonials />
        <Blogs />
      </div>
  )
}

export default HomePage;