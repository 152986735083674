import React from 'react';
import './SingleService.css';

import { FaqItem, PageTitle, SidebarHeading } from '../../../components';
import { serviceContent } from '../../../constants';

import { MdDoubleArrow } from 'react-icons/md';
 
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'; 

  

function ServiceDetails() { 

    const location = useLocation();
    const service = location.state; 

    const paragraphs = service.paragraph; 

    return ( 
        <>
            <PageTitle title={'single service'} page={'single service'} />
            <section className='single-service'>
                
                <div className='service-info'>
                    <div className='image'>
                        <img src={service.image} alt='Service' />
                    </div>
                    <div className='content'>
                        <h3 className='main-heading'>{service.title}</h3>

                        <p>{paragraphs.para1}</p>  
                        <p>{paragraphs.para2}</p> 

                        <div className='service-gallery'>
                            {
                                (service.gallery).map((item) => {
                                    return <img key={item.id} src={item.image} alt='' />
                                })
                            }
                        </div> 

                        <h3 className='sub-heading'>How we work?</h3>

                        <ul className='service-features'>
                            {
                                (service.points).map((item) => {
                                    return <li key={item.id}><MdDoubleArrow className='icon' />{item.title}</li>
                                })
                            }
                        </ul> 

                        <h3 className='sub-heading'>Frequently Asked Questions:</h3>

                        <div className='service-faqs'> 
                        {
                            service.faqs.map((faq) => {
                                return (
                                    <FaqItem key={faq.id} title={faq.heading} content={faq.content} />
                                )
                            })
                        }
                        </div>
                    
                    </div>

                </div>

                <div className='service-sidebar'> 
                    <SidebarHeading title='all services' />
                    {
                        serviceContent.map((service) => {
                            return(
                                <Link to={{
                                    pathname: '/Services/' + service.title,
                                    }}     
                                    location = {{service}}
                                    state= {service}
                                    key={service.id}>
                                    <h3><MdDoubleArrow className='icon' />{service.title}</h3>
                                </Link>
                            )
                        })
                    }
                </div>

            </section>
        </>
    )
}

export default ServiceDetails;
 