import { images } from '../constants';

export const causeCategories = [  
    {
        id: 1,
        title: 'Learning Quran',
        quantity: '15',
    },
    { 
        id: 2,
        title: 'Marriage Service',
        quantity: '5',
    }, 
    { 
        id: 3, 
        title: 'Counseling',
        quantity: '10',
    },
    { 
        id: 4,
        title: 'Community service',
        quantity: '15',
    },
    { 
        id: 5,
        title: 'Mosque Development',
        quantity: '8',
    },
    {
        id: 6,
        title: 'Funeral Service',
        quantity: '4',
    },
];

export const causePosts = [ 
    {
        id: 1,
        image: images.recentCause1,
        title: 'Donate School',
        date: '25th mar, 2022',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        raised: '2000',
        goal: '5000',
    },
    {
        id: 2,
        image: images.recentCause2,
        title: 'save children',
        date: '10th jun, 2022',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        raised: '3000',
        goal: '4000',
    }, 
    {
        id: 3,
        image: images.recentCause3,
        title: 'Children Education',
        date: '19th oct, 2022',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        raised: '9000',
        goal: '10000',
    },
];

export const causeTags = [
    {
        id: 1,
        title: 'books',
    },
    {
        id: 2,
        title: 'worship',
    },
    {
        id: 3,
        title: 'education',
    },
    {
        id: 4,
        title: 'Health', 
    },
    {
        id: 5,
        title: 'Donate', 
    },
    {
        id: 6,
        title: 'Quran',
    }, 
];