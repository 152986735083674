import React from 'react'; 
import './Prayers.css';

import { Heading } from '../../../components';
import { prayers } from '../../../constants';

   

const Prayers = () => {    
  return (
    <section className='prayer-timing linear-bg'>

      <Heading title={'prayer timing'} />

      <div className='box-container'>
      {
        prayers.map((prayer) => {
          return( 
          <div className='prayer-item' key={prayer.id}>
            <h4>{prayer.name}</h4>
            <ul className='content'>
              <li><h6>start:</h6><span>{prayer.start}</span></li>
              {prayer.iqamah.length > 0 && <li><h6>iqamah:</h6><span>{prayer.iqamah}</span></li>}   
            </ul>
          </div>
          )
        })
        }
        </div>

    </section>
  )
}

export default Prayers;