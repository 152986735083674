import React from 'react';
import './CauseSidebar.css';

import { SidebarHeading, Cause } from '../../components';
import { causeCategories, causePosts, causeTags } from '../../constants';

import { BsSearch } from 'react-icons/bs';
 
 
  
const CauseSidebar = () => {
  return (  
    <div className='sidebar cause-sidebar'>
 
        <div className='search sidebar-item'>
            <SidebarHeading title='search' />
            <div className='input-box'>
                <input type="search" id="search" placeholder="search..." />
                <label htmlFor="search"><BsSearch /></label>
            </div>
        </div>


        <div className='category sidebar-item'>
            <SidebarHeading title='categories' />
            <div className='box-container'>
            {
                causeCategories.map((category) => {
                    return(
                        <div className='item' key={category.id}>
                            <a href="#">{category.title}</a>
                            <p>({category.quantity})</p>
                        </div>
                    )
                })
            }
            </div>   
        </div>  


        <div className='recent-post sidebar-item'>
            <SidebarHeading title='recent posts' />
            <div className='box-container'>
            {
                causePosts.map((cause) => {  
                    return( 
                        <Cause 
                        key={cause.id} 
                        image={cause.image} 
                        title={cause.title}
                        date={cause.date}
                        cause={cause} 
                      />)
                })
            } 
            </div>
        </div>


        <div className='tags sidebar-item'>
            <SidebarHeading title='tags' />
            <div className='box-container'>
                {
                    causeTags.map((tag) => {
                        return <div className='tag-item' key={tag.id}>{tag.title}</div>
                    })
                }
            </div>
        </div>
        

    </div>
  )
}

export default CauseSidebar;