import React from 'react';

import { Footer, Header, ScrollTop } from './components';
import { HomePage, ContactUs, AboutUs, TeamPage, TestimonialsPage, Faqs, ServicePage, BlogGrid, BlogList, SingleBlog, ShopGrid, ShopList, SingleProduct, Wishlist, Checkout, Cart, OrderList, SingleOrder, SingleService, Login, Register, ForgotPwd, ChangePwd, Profile, EditProfile, EditAddress, SingleTeam, CausePage, SingleCause, EventPage, SingleEvent, GalleryPage } from './sections';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



const App = () => {
  return (
    <div className='App'>

      <Header />

      <Routes>
 
        {/*Home*/}
        <Route path='/' element={<HomePage />} />

        {/*About*/}
        <Route path='/About-us' element={<AboutUs />} /> 
        <Route path='/Team' element={<TeamPage />} />
        <Route path='/Team/:id' element={<SingleTeam />} />
        <Route path='/Team/singleTeam' element={<SingleTeam />} /> 
        <Route path='/Testimonials' element={<TestimonialsPage />} />
        <Route path='/Faqs' element={<Faqs />} />  

        {/*Services*/}
        <Route path='/Services' element={<ServicePage />} /> 
        <Route path='/Services/:id' element={<SingleService />} />
        <Route path='/Services/Single-service' element={<SingleService />} /> 

        {/*Causes*/}
        <Route path='/Causes' element={<CausePage />} />  
        <Route path='/Causes/:id' element={<SingleCause />} /> 
        <Route path='/Causes/Single-cause' element={<SingleCause />} /> 

        {/*Events*/}
        <Route path='/Events' element={<EventPage />} />  
        <Route path='/Events/:id' element={<SingleEvent />} />  
        <Route path='/Events/Single-event' element={<SingleEvent />} /> 

        {/*Pages*/}
        <Route path='/Gallery' element={<GalleryPage />} />  s
        <Route path='/Login' element={<Login />} /> 
        <Route path='/Register' element={<Register />} /> 
        <Route path='/Forgot-pwd' element={<ForgotPwd />} /> 
        <Route path='/Change-pwd' element={<ChangePwd />} /> 
        <Route path='/Profile' element={<Profile />} /> 
        <Route path='/Edit-profile' element={<EditProfile />} />  
        <Route path='/Edit-address' element={<EditAddress />} />
  
        {/*Blogs*/}
        <Route path='/Blog-grid' element={<BlogGrid />} /> 
        <Route path='/Blog-list' element={<BlogList />} /> 
        <Route path='/Blogs/:id' element={<SingleBlog  />} />
        <Route path='/Blogs/Single-blog' element={<SingleBlog />} />

        {/*Shop*/}
        <Route path='/Shop-grid' element={<ShopGrid />} /> 
        <Route path='/Shop-list' element={<ShopList />} />
        <Route path='/Shop/:id' element={<SingleProduct />} />
        <Route path='/Shop/Single-product' element={<SingleProduct />} />
        <Route path='/Wishlist' element={<Wishlist />} />
        <Route path='/Checkout' element={<Checkout />} />
        <Route path='/Cart' element={<Cart />} />
        <Route path='/Order-list' element={<OrderList />} />
        <Route path='/Single-order' element={<SingleOrder />} />
        <Route path='/Single-order/:id' element={<SingleOrder />} />
 
        {/*Contact*/}
        <Route path='/contact-us' element={<ContactUs />} />

      </Routes>

      <Footer />
      <ScrollTop />
      
    </div>
  )
}

export default App;
