const prayers = [ 
    {
        id: 1,
        name: 'fajr',
        start: '05:13 AM',
        iqamah: '06:15 AM',
    },
    {
        id: 2,
        name: 'sunrise',
        start: '06:50 AM',
        iqamah: '',
    },
    {
        id: 3,
        name: 'dhuhr',
        start: '11:39 AM',
        iqamah: '01:30 PM',
    },
    {
        id: 4,
        name: 'asr',
        start: '02:45 PM',
        iqamah: '03:30 PM',
    },
    {
        id: 5,
        name: 'maghrib',
        start: '04:28 PM',
        iqamah: '04:30 PM',
    },
    {
        id: 6,
        name: 'isha',
        start: '05:48 PM',
        iqamah: '07:45 PM',
    },

];

export default prayers;