import React from 'react'; 
import './Causes.css';

import { Heading, Cause } from '../../../components';
import { causeContent } from '../../../constants';

   

const Causes = () => {   

  const size = 3;   
  const causes = causeContent.slice(0, size);

  return (
    <section className='causes'>

      <Heading title={'Our Causes'} />

      <div className='box-container'>

      { 
        causes.map((cause) => {  
          return( 
          <Cause 
            key={cause.id} 
            image={cause.image} 
            title={cause.title}
            date={cause.date}
            content={cause.content} 
            raised={cause.raised}
            goal={cause.goal} 
            cause={cause} 
          />)
        })
      }
 
      </div> 

    </section>
  )
}

export default Causes;