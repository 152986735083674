import React from 'react';
import './SingleCause.css';

import { CauseSidebar, PageTitle } from '../../../components';

import { AiTwotoneCalendar } from 'react-icons/ai';
import { BsCurrencyDollar } from 'react-icons/bs';
 
import { useLocation } from "react-router-dom";

 

function SingleCause() {
 
    const location = useLocation();
    const cause = location.state;  

    return (
        <>
            <PageTitle title={'single cause'} page={'single cause'} />
            <section className='single-cause'>
                
                <div className='cause-info'>
                    <div className='image'>
                        <img src={cause.image} alt='Cause' />
                    </div>

                    <div className='intro'>
                        <h3 className='main-heading'>{cause.title}</h3>
                        <div className='date'><AiTwotoneCalendar className='icon' /><span>{cause.date}</span></div>
                        <div className='skill-bar'>
                            <span className='skill-progress' style={{width: Math.floor((cause.raised * 100) / cause.goal) + `%`}}>
                                <span className='skill-no'>{Math.floor((cause.raised * 100) / cause.goal)}%</span>
                            </span>
                        </div>
                        <div className='amount'>
                            <p>Raised: <span>${cause.raised}</span></p>
                            <p>Goal: <span>${cause.goal}</span></p>
                        </div>
                    </div>

                    <form className='form content' action="comment.php">

                        <p>{cause.paragraph}</p> 

                        <div className='item amount-box'>
                            <h4>donation amount:</h4>
                            <div className='amount-input-box'>
                                <label htmlFor="amount"><BsCurrencyDollar className='icon' /></label>
                                <input type="number" id="amount" placeholder="$10" />
                            </div>
                            <div className='amount-choices'>
                                <div className='amount-item'>$10.00</div>
                                <div className='amount-item'>$30.00</div>
                                <div className='amount-item'>$50.00</div>
                                <div className='amount-item'>$75.00</div>
                                <div className='amount-item'>$100.00</div>
                            </div>
                        </div>

                        <div className='item'>
                            <h4>payment amount:</h4>
                            <div className='payment-options'>
                                <div className='payment-item'>
                                    <input type="radio" id="card" name="payment" />
                                    <label htmlFor="card" className='payment-label'>Payment by card</label>
                                </div>
                                <div className='payment-item'>
                                    <input type="radio" id="offline" name="payment" />
                                    <label htmlFor="offline" className='payment-label'>offline donation</label>
                                </div>
                            </div>
                        </div>

                        <div className='item personal-info'>
                            <h4>personal information:</h4> 
                            <div className='input-box'>
                                <input type="text" className='box' name="name" id="name" placeholder="enter name" />
                                <input type="email" className='box' name="email" id="email" placeholder="enter email" /> 
                            </div>
                            <textarea className='box' rows="10" cols="30" name="comment" id="comment" placeholder="your message"></textarea>
                        </div>

                        <button type="submit" className='btn' name="donate" id="donate">donate now</button>

                    </form>

                </div>

                <CauseSidebar />
            </section>
        </>
    )
}

export default SingleCause;
 