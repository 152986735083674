import { FaMosque, FaUserGraduate, FaUsers } from 'react-icons/fa';
import { BsCalendarCheck } from 'react-icons/bs';

  
const counter = [
    { 
        id: 1,
        icon: <FaMosque />,
        title: 'Mosques',
        count: '20',
    },
    {
        id: 2,
        icon: <BsCalendarCheck />,
        title: 'Events',
        count: '500',
    },
    {
        id: 3,
        icon: <FaUserGraduate />,
        title: 'Students',
        count: '1000',
    },
    {
        id: 4,
        icon: <FaUsers />,
        title: 'Volunteers',
        count: '200',
    },
 
];

export default counter;