import React from 'react';

import { Cause, PageTitle } from '../../../components';
import { causeContent } from '../../../constants';

 
  
const CausePage = () => {
  const size = 6;   
  const causes = causeContent.slice(0, size);
  return (
    <>
      <PageTitle title={'our causes'} page={'causes'} />
      <section className='causes'>
        <div className='box-container'>
        { 
              causes.map((cause) => {  
                return( 
                <Cause 
                  key={cause.id} 
                  image={cause.image} 
                  title={cause.title}
                  date={cause.date}
                  content={cause.content} 
                  raised={cause.raised}
                  goal={cause.goal} 
                  cause={cause} 
                />)
              })
            }
        </div>
      </section>
    </>
  )
}

export default CausePage;