import React from 'react'; 
import './Events.css';

import { Heading, Event } from '../../../components';
import { eventContent } from '../../../constants';

  

const Events = () => {     
  
  const size = 3;   
  const events = eventContent.slice(0, size);

  return (
    <section className='events'>

      <Heading title={'our events'} />

      <div className='box-container'>
      { 
        events.map((event) => {  
          return(
          <Event 
            key={event.id} 
            image={event.image} 
            title={event.title}
            content={event.content} 
            date={event.date}
            time={event.time}
            location={event.location} 
            event={event} 
          />) 
        })
      }
      </div> 

    </section>
  )
}

export default Events;