import React from 'react'; 
import './About.css';

import { Heading, Button } from '../../../components';
import { images } from '../../../constants';
   

const About = () => {    
  return (
    <section className='about'>

      <Heading title={'about us'} />

      <div className='box-container'>

        <div className='image'>
          <div className='sub-image one'>
            <img src={images.about1} alt='' />
          </div>
          <div className='sub-image two'>
            <img src={images.about2} alt='' />
            <img src={images.about3} alt='' />
          </div>
        </div>

        <div className='content'>
          <h3>The most complete gift of God is a life based on knowledge</h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum iusto minima quo, recusandae perspiciatis aspernatur, rerum tempora vitae aperiam enim, dicta maxime? Eum repellat velit perspiciatis eligendi rerum voluptate repellendus.</p>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum iusto minima quo, recusandae perspiciatis aspernatur, rerum tempora vitae aperiam enim.</p>
          <Button link={'contact-us'} title={'contact us'} />
        </div>
 
      </div> 

    </section>
  )
}

export default About;