import { images } from '.';


const services = [  
    {  
        id: 1,
        image: images.service1,
        title: 'Learning Quran',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service11},
            {id: 2, image: images.service12},
        ],
        points: [ 
            {id: 1, title: 'Reading Quran Basics'},
            {id: 2, title: 'Learn Quran Recitation'},
            {id: 3, title: 'Learn Tajweed Rules'},
            {id: 4, title: 'Memorize The Quran'},
            {id: 5, title: 'Get Ijazah'},
        ],
        faqs: [
            {
                id: 1, 
                heading: 'Do you offer any online live class?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 2,
                heading: 'How can we pay fee?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'How can I become more involved at the center?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'Are there any support programs?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 2,
        image: images.service2,
        title: 'Marriage Service',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service11},
            {id: 2, image: images.service12},
        ],
        points: [ 
            {id: 1, title: 'Reading Quran Basics'},
            {id: 2, title: 'Learn Quran Recitation'},
            {id: 3, title: 'Learn Tajweed Rules'},
            {id: 4, title: 'Memorize The Quran'},
            {id: 5, title: 'Get Ijazah'},
        ],
        faqs: [
            {
                id: 1, 
                heading: 'Do you offer any online live class?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 2,
                heading: 'How can we pay fee?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'How can I become more involved at the center?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'Are there any support programs?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 3,
        image: images.service3,
        title: 'Counseling',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service11},
            {id: 2, image: images.service12},
        ],
        points: [ 
            {id: 1, title: 'Reading Quran Basics'},
            {id: 2, title: 'Learn Quran Recitation'},
            {id: 3, title: 'Learn Tajweed Rules'},
            {id: 4, title: 'Memorize The Quran'},
            {id: 5, title: 'Get Ijazah'},
        ],
        faqs: [
            {
                id: 1, 
                heading: 'Do you offer any online live class?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 2,
                heading: 'How can we pay fee?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'How can I become more involved at the center?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'Are there any support programs?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 4,
        image: images.service4,
        title: 'Community service',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service11},
            {id: 2, image: images.service12},
        ],
        points: [ 
            {id: 1, title: 'Reading Quran Basics'},
            {id: 2, title: 'Learn Quran Recitation'},
            {id: 3, title: 'Learn Tajweed Rules'},
            {id: 4, title: 'Memorize The Quran'},
            {id: 5, title: 'Get Ijazah'},
        ],
        faqs: [
            {
                id: 1, 
                heading: 'Do you offer any online live class?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 2,
                heading: 'How can we pay fee?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'How can I become more involved at the center?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'Are there any support programs?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 5,
        image: images.service5,
        title: 'Mosque Development',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service11},
            {id: 2, image: images.service12},
        ],
        points: [ 
            {id: 1, title: 'Reading Quran Basics'},
            {id: 2, title: 'Learn Quran Recitation'},
            {id: 3, title: 'Learn Tajweed Rules'},
            {id: 4, title: 'Memorize The Quran'},
            {id: 5, title: 'Get Ijazah'},
        ],
        faqs: [
            {
                id: 1, 
                heading: 'Do you offer any online live class?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 2,
                heading: 'How can we pay fee?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'How can I become more involved at the center?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'Are there any support programs?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 6,
        image: images.service6,
        title: 'Funeral Service',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service11},
            {id: 2, image: images.service12},
        ],
        points: [ 
            {id: 1, title: 'Reading Quran Basics'},
            {id: 2, title: 'Learn Quran Recitation'},
            {id: 3, title: 'Learn Tajweed Rules'},
            {id: 4, title: 'Memorize The Quran'},
            {id: 5, title: 'Get Ijazah'},
        ],
        faqs: [
            {
                id: 1, 
                heading: 'Do you offer any online live class?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 2,
                heading: 'How can we pay fee?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'How can I become more involved at the center?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'Are there any support programs?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },

];

export default services;