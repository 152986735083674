import { images } from '../constants';

const home = [
    {  
        id: 1,
        image: images.home1,
        title: 'And [He revealed] that the masjids are for Allah, so do not invoke with Allah anyone',
        content: '(SURAH AL-JINN VERSE 18)',
    },
    {
        id: 2,
        image: images.home2,
        title: 'And seek help through patience and prayer. Indeed, it is a burden except for the humble',
        content: '(SURAH AL-BAQARAH VERSE 45)',   
    },
    { 
        id: 3,
        image: images.home3,
        title: 'Read, ˹O Prophet,˺ in the Name of your Lord Who created',
        content: '(SURAH AL-ALAQ VERSE 1)',     
    },

];

export default home;