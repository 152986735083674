import React from 'react';
import './Counter.css';

import CountUp from 'react-countup';
import { counter } from '../../../constants';



const Counter = () => { 
  return ( 
    <div className='counting'> 
    { 
      counter.map((count) => {
        return (
          <div className='box' key={count.id}>
              <div className='icon'>{count.icon}</div>
              <CountUp className='count' end={count.count} duration={3} suffix={'+'} />
              <h3>{count.title}</h3>
          </div>
        ) 
      })
    }
    </div>
  )
}

export default Counter;