import blogs from './blogs';
import causes from './causes';
import events from './events';
import orders from './orders';
import products from './products';
import services from './services';
import team from './team';



const navbar = [ 
    {
        id: 1,
        mainLink : {link: '/', title: 'home'},
        subLink: [],
    },
    {
        id: 2,
        mainLink : {link: '#', title: 'about'},
        subLink: [
            {id: 1, link: '/About-us', title: 'about us'},
            {id: 2, link: '/Team', title: 'team'},
            {id: 3, link: '/Team/singleTeam', title: 'single team', state: team[0]}, 
            {id: 4, link: '/Testimonials', title: 'testimonials'}, 
            {id: 5, link: '/Faqs', title: 'faqs'},
        ],
    },
    {
        id: 3,
        mainLink : {link: '#', title: 'services'},
        subLink: [
            {id: 1, link: '/Services', title: 'services'},
            {id: 2, link: '/Services/Single-service', title: 'single service', state: services[0]},
        ],
    },
    {
        id: 4,
        mainLink : {link: '#', title: 'causes'},
        subLink: [
            {id: 1, link: '/Causes', title: 'causes'},
            {id: 2, link: '/Causes/Single-cause', title: 'single cause', state: causes[0]}, 
        ],
    },
    {
        id: 5,
        mainLink : {link: '#', title: 'events'},
        subLink: [
            {id: 1, link: '/Events', title: 'events'},
            {id: 2, link: '/Events/Single-event', title: 'single event', state: events[0]}, 
        ],
    },
    {
        id: 6,
        mainLink : {link: '#', title: 'pages'},
        subLink: [
            {id: 1, link: '/Gallery', title: 'Gallery'},
            {id: 2, link: '/Login', title: 'login'},
            {id: 3, link: '/Register', title: 'register'},
            {id: 4, link: '/Forgot-pwd', title: 'forgotPwd'},
            {id: 5, link: '/Change-pwd', title: 'changePwd'},
            {id: 6, link: '/Profile', title: 'profile'},
            {id: 7, link: '/Edit-profile', title: 'edit profile'},
            {id: 8, link: '/Edit-address', title: 'edit Address'},
        ],
    }, 
    {
        id: 7,
        mainLink : {link: '#', title: 'blog'},
        subLink: [
            {id: 1, link: '/Blog-grid', title: 'blog grid'},
            {id: 2, link: '/Blog-list', title: 'blog list'},
            {id: 3, link: '/Blogs/Single-blog', title: 'single blog', state: blogs[0]},
        ],
    },
    {
        id: 8,
        mainLink : {link: '#', title: 'shop'},
        subLink: [
            {id: 1, link: '/Shop-grid', title: 'shop grid'},
            {id: 2, link: '/Shop-list', title: 'shop list'},
            {id: 3, link: '/Shop/Single-product', title: 'single product', state: products[0]},
            {id: 4, link: '/Wishlist', title: 'wishlist'},
            {id: 5, link: '/Checkout', title: 'checkout'},
            {id: 6, link: '/Cart', title: 'cart'},
            {id: 7, link: '/Order-list', title: 'order list'},
            {id: 8, link: '/Single-order', title: 'single order', state: orders[0]},
        ],
    },
    {
        id: 9,
        mainLink : {link: '/Contact-us', title: 'contact'},
        subLink: [],
    },
];

export default navbar;