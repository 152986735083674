import React, {useState, useEffect} from 'react';
import './Header.css';

import { HeaderItem, MobileNavbar, Logo, Button } from '../../components';
import { navbar } from '../../constants';

import { FaBars, FaUserAlt, FaCartArrowDown } from 'react-icons/fa';

import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
 
  
    
const Header = () => { 
  const [click, setClick] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const {pathname} = useLocation();

  const handleClick = () => {
    setClick(true); 
    setOpen(true); 
  }

  const closeMenuBar = () => {
    setClick(false);
    setOpen(false); 
  }

  /* Close the Navbar between navigation */
  useEffect(() => {
    setOpen(false); 
  }, [ pathname ]);
     
  /* Manage On Scroll Functionality */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    window.addEventListener('scroll', stickyHeader);
    return () => {
        window.removeEventListener('scroll', stickyHeader);
    };
  });

  const stickyHeader = (e) => {
    const header = document.querySelector('.header-2');
    const scrollTop = window.scrollY;
    scrollTop >= 60 ? header.classList.add('active') : header.classList.remove('active');
};


  const handleScroll = (e) => {
    /* Close Menubar On Scroll */
    const navbar = document.querySelector('.navbar');
    navbar.classList.remove('active');
    setClick(false);
    setOpen(false);  
  };

  const removeActive = (e) => {
    const header2 = document.querySelector('.header-2');
    header2.classList.remove('active'); 
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [removeActive])



  return (  

    <nav className='header'>

      <div className='header-1'>
 
        <Logo /> 

        <ul className='header-contacts'>
          <li><FaUserAlt className='icon' /><h6>phone:</h6><span>+111-222-333</span></li>
          <li><FaUserAlt className='icon' /><h6>email:</h6><span className='gmail'>abc@gmail.com</span></li>
        </ul>

        <div className='action'>
          <div className='icon-container'>
            <Link className='icon' id='login' to='/login'><FaUserAlt /></Link>
            <Link className='icon' id='cart' to='/cart'><FaCartArrowDown /></Link>
          </div>
          <Button link={'causes'} title={'donate'} /> 
        </div>
        
      </div>

      <div className='header-2'>

        {(click && isOpen) && <MobileNavbar close={closeMenuBar} />}

        <div className='navbar' >
          {
            navbar.map((item) => {
              return <HeaderItem key={item.id} {...item} />
            })
          }
        </div>

        <div className='icon menu-btn' id='menu' onClick={handleClick}><FaBars /></div>

      </div>

    </nav>
   )
 }
 
 export default Header;