import { FaHandPointUp, FaPray, FaHandHoldingUsd, FaKaaba } from 'react-icons/fa';
import { BsMoonFill } from 'react-icons/bs';

  
const pillars = [
    {  
        id: 1,
        icon: <FaHandPointUp />,
        title: 'shadah',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit.',
    },
    {
        id: 2,
        icon: <FaPray />,
        title: 'prayer',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit.',
    },
    {
        id: 3,
        icon: <BsMoonFill />,
        title: 'fasting',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit.',
    },
    {
        id: 4,
        icon: <FaHandHoldingUsd />,
        title: 'zakat',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit.',
    },
    {
        id: 5,
        icon: <FaKaaba />,
        title: 'hajj',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit.',
    },
 
];

export default pillars;